<template>
<v-container fluid>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ $t('site_search.title') }}</v-card-title>
          <v-card-text>
            <v-text-field ref="str" v-model="search_str" :label="$t('')" @change="site_search()"></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="search_executed">
          <v-card-title v-if="results_loading">{{ $t('site_search.loading') }}</v-card-title>
          <v-card-title v-else-if="results.length == 0 && search_str != ''">{{ $t('site_search.no_results') }}</v-card-title>
          <v-card-title v-else>{{ $t('site_search.results') }}</v-card-title>
          <v-card-text>
            <v-list three-line>
              <template v-for="result in results">
                <v-list-item :key="result.id" route :to="to_product(result.id)">
                  <v-list-item-avatar tile size="50">
                    <v-img contain :src="to_url(result.imgSrc)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="result.code"></v-list-item-title>
                    <v-list-item-subtitle v-html="result.descriptionShort"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</v-container>
</template>

<script>
// import { i18n } from "@/i18n/i18n"
import ApiService from "@/services/api_service"


export default {
  components: {  },

  data: () => ({
    search_str: '',
    search_executed: false,
    results_loading: false,
    results: []
  }),
  watch: {
   
  },
  methods: {
    site_search() {
        if(this.search_str && this.search_str.length > 3) {
            this.$store.commit('set_search_string', this.search_str)
            this.results_loading = true
            this.search_executed = true
            ApiService.get(`/search/site/${this.search_str}`)
            .then(response => {
                this.results = response.data
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
              this.results_loading = false
            })
        }
    },
    to_url(val) {
      return '/pictures/picture/pl/' + val
    },
    to_product(val) {
      var k = encodeURI(this.search_str)
      return '/product/' + val + '/searchfor/' + encodeURI(k)
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.$store.getters.get_search_string != '') {
        this.search_str = this.$store.getters.get_search_string
        this.$refs.str.focus()
        this.$refs.str.blur()
      }
        this.$refs.str.focus()
    })
  },

       
};
</script>

<style scoped>
</style>
